import labels from "../../../helpers/labels.json";

export const sidebar_options = [
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/my_cases.svg"),
    title: labels.my_cases,
    path: "/view/cases",
    listItems: [
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.my_cases_mediation,
        path: "/view/cases?caseType=mediation",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.my_cases_arbitration,
        path: "/view/cases?caseType=arbitration",
      },
    ],
  },
  {
    icon: require("../../../assets/images/home.svg"),
    inactive: require("../../../assets/images/homeInactive.svg"),
    blueIcon: require("../../../assets/images/Home_active_Blue.svg"),
    title: labels.mis_dashboard,
    path: "/view/mis-dashboard",
  },
  // {
  //   icon: require("../../../assets/images/profileInactive.svg"),
  //   inactive: require("../../../assets/images/profileInactive.svg"),
  //   title: labels.order_dictation,
  //   path: "/mediator/order-dictation",
  // },
];
