import API from "../api/manager";
import config from "../api/config";

const getCase = (id, query = "") => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}${query}`,
  });
};

const getCases = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.passiveNeutralCases + query,
  });
};

const caseStats = (query) => {
  return API({ method: "GET", url: config.urls.case.stats + query });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const caseUpdateDocument = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadCase + `/${caseId}/dealAgreement/${docId}`,
    data,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement${query}`,
  });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};
const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const caseDocumentListing = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.case_document_uploads + query,
  });
};

const uploadStats = () => {
  return API({
    method: "GET",
    url: config.urls.case.uploadStats,
  });
};

const saveSummary = (data, id, action) => {
  return API({
    method: "POST",
    url: config.urls.case.saveSummary + `/${id}/${action}`,
    data,
  });
};

const uploadAgreement = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}`,
    data,
  });
};

const updateAgreement = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}/${docId}`,
    data,
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const addNotes = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.notes}/${caseId}`,
    data,
  });
};

const getNotes = (caseId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.notes}/${caseId}${query}`,
  });
};

const getNotificationCount = () => {
  return API({ method: "GET", url: config.urls.case.notificationCount });
};

const clearNotification = () => {
  return API({ method: "POST", url: config.urls.notificationCount, data: {} });
};

const getPendingActions = () => {
  return API({ method: "GET", url: config.urls.case.activities });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const addBundle = (data, id) => {
  return API({
    method: "POST",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data,
  });
};

const getBundleName = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.customBundle}/${id}`,
  });
};

const getDocumentList = (caseId, id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.bundleDocList}/${caseId}/${id}`,
  });
};

const updateBundle = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.customBundle}/${caseId}/${id}`,
    data,
  });
};

const changeDocType = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadDocCase + `/${caseId}/${docId}`,
    data,
  });
};

const getTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getTranscriptionData}/${id}`,
  });
};

const enableLiveTranscription = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.enableLiveTranscription}/${id}`,
  });
};

const getLiveTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}/${id}`,
  });
};

const getLiveTranscription = (transcriptId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscription}/${transcriptId}`,
  });
};

const customBundleDelete = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data: {},
  });
};

const editBundleName = (data, caseId, bundleId) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.customBundle + `/${caseId}/${bundleId}`,
    data,
  });
};

const getLiveTranscriptDet = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.liveTranscriptDet}/${id}${query}`,
  });
};

const downloadComments = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.downloadComments}/${id}`,
  });
};

const downloadTranscripts = (data) => {
  return API({
    method: "POST",
    url: `/downloadTranscripts`,
    data,
  });
};

const dictateOrder = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.orderDictation.dictateOrder}`,
    data,
  });
};

const getCaseIds = () => {
  return API({
    method: "GET",
    url: `${config.urls.orderDictation.getCaseIds}`,
  });
};

const dictateOrderOne = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.orderDictation.dictateOrder}/${id}`,
    data,
  });
};

const dictateOrderContent = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.orderDictation.dictateOrderContent + query}`,
  });
};

const publishDictationContent = (data, id) => {
  return API({
    method: "POST",
    url: `${config.urls.orderDictation.publishDictationContent}/${id}`,
    data,
  });
};

const nextHearingDate = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.case + `/${id}`,
    data,
  });
};

const addPageNumberBundle = (id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addPageNumberBundle + `/${id}`,
  });
};

const generateTableofContentsBundle = (id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.generateTableofContentsBundle + `/${id}`,
  });
};

const hearingVisibilityType = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.customBundle + `/${caseId}/${id}`,
    data,
  });
};

const addAnnotationToBundles = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addAnnotationToBundles + `/${id}`,
    data,
  });
};

const deletePagesFromBundle = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.deletePagesFromBundle}/${id}`,
    data,
  });
};

const addSectionOrPagesToBundle = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addSectionOrPagesToBundle + `/${id}`,
    data,
  });
};

const addPagesToBundle = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.addPagesToBundle}/${id}/page`,
    data,
  });
};

const getTranscriptionRefreshStatus = (meetingId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getTranscriptionRefreshStatus}/${meetingId}`,
  });
};

const postTranscriptionData = (data) => {
  return API({
    method: "POST",
    url: config.urls.transcription.postTranscriptionData,
    data,
  });
};

const GetMeetingcases = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.mettingcase + `/${id}`,
  });
};

const getTranscriptionType = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.transcription.getTranscriptionType}/${caseId}`,
  });
};

const getIndividualCase = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.passiveNeutralCases}/${caseId}`,
  });
};

const casesForMIS = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.casesForMIS + query,
  });
};

const downloadMIS = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.downloadMIS,
    data,
  });
};

export default {
  getCase,
  getCases,
  caseStats,
  caseAddDocument,
  getcaseDocuments,
  caseUpdateDocument,
  downloadCaseAgreement,
  mediations,
  sendComment,
  getMeeting,
  caseDocumentListing,
  uploadStats,
  saveSummary,
  uploadAgreement,
  updateAgreement,
  addNotes,
  getNotes,
  getNotificationCount,
  clearNotification,
  getPendingActions,
  getPartyListByCaseId,
  getMeetingVideo,
  addBundle,
  getBundleName,
  getDocumentList,
  updateBundle,
  changeDocType,
  getTranscriptionData,
  enableLiveTranscription,
  getLiveTranscriptionData,
  getLiveTranscription,
  customBundleDelete,
  editBundleName,
  getLiveTranscriptDet,
  downloadComments,
  downloadTranscripts,
  getCaseIds,
  dictateOrder,
  dictateOrderContent,
  dictateOrderOne,
  publishDictationContent,
  nextHearingDate,
  addPageNumberBundle,
  generateTableofContentsBundle,
  hearingVisibilityType,
  addAnnotationToBundles,
  deletePagesFromBundle,
  addSectionOrPagesToBundle,
  addPagesToBundle,
  getTranscriptionRefreshStatus,
  postTranscriptionData,
  GetMeetingcases,
  getTranscriptionType,
  getIndividualCase,
  casesForMIS,
  downloadMIS,
};
