import { CLAIMENT_STR } from "../../../helpers/constants";
import _ from "lodash";

export function getCaseLabelByStatus({
  detailedStage,
  agentRole,
  uploadedDocuments,
  subscriptionKind,
}) {
  switch (detailedStage) {
    case "draft":
      return "Draft";
    case "pendingRegistrationFee":
      return "Awaiting Case Fee";
    case "negotiationReached":
      return subscriptionKind === "adhoc"
        ? "Settled"
        : "Awaiting Agreement Fee";
    case "negotiationOngoing":
      return "Under Resolution";
    case "claimantPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : agentRole === CLAIMENT_STR
        ? "Awaiting Agreement"
        : "Awaiting Agreement Fee";
    case "respondentPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : agentRole !== CLAIMENT_STR
        ? "Awaiting Agreement"
        : "Awaiting Agreement Fee";
    case "awaitingRespondentAcceptance":
      return agentRole === "respondent"
        ? "Awaiting Case Fee"
        : "Waiting For Respondent";
    case "bothPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : "Awaiting Agreement";
    case "waitingForCaseApproval":
      return "Waiting For Case Approval";
    case "waitingForArbitratorConfirmation":
      return "Waiting for Arbitrator Confirmation";
    case "noticeToArbitrate":
      return "Notice to Arbitrate";
    case "arbitrationReferenced":
      return "Arbitration Referenced";
    case "arbitrationNominated":
      return "Arbitrator Nominated";
    case "arbitrationConfirmed":
      return "Arbitrator Confirmed";
    case "firstNoticeSent":
      return "First Notice Sent";
    case "statementOfClaimFiled":
      return "Statement of Claim Filed";
    case "awaitingForSec17Details":
      return "Awaiting for Sec 17 details";
    case "sec17PetitionFiled":
      return "Sec 17 Petition Filed";
    case "sec17Ordered":
      return "Sec 17 Ordered";
    case "awaitingSec17Execution":
      return "Awaiting Sec 17 Execution";
    case "sec17ExtensionMemoFiled":
      return "Sec 17 Extension Memo Filed";
    case "sec17ExtensionMemoOrdered":
      return "Sec 17 Extension Memo Ordered";
    case "fsOrdered":
      return "FS Ordered";
    case "attachmentOrdered":
      return "Attachment Ordered";
    case "garnisheeProhibitaryOrder":
      return "Garnishee Prohibitary Order";
    case "garnisheeFinalOrder":
      return "Garnishee Final Order";
    case "sec17Closed":
      return "Sec 17 Closed";
    case "statementOfDefenceFiled":
      return "Statement of Defence Filed";
    case "rejoinderFromClaimant":
      return "Rejoinder from Claimant";
    case "surrejoinderFromRespondent":
      return "Surrejoinder From Respondent";
    case "2ndNoticeMOM":
      return "2nd Notice MOM";
    case "evidenceFiledByClaimant":
      return "Evidence Filed by Claimant";
    case "evidenceFiledByRespondent":
      return "Evidence Filed by Respondent";
    case "crossExamination":
      return "Cross Examination";
    case "argumentsByClaimant":
      return "Arguments by Claimant";
    case "argumentsByRespondent":
      return "Arguments by Respondent";
    case "reservedForAward":
      return "Reserved for Award";
    case "awardPassed":
      return "Award Passed";
    case "quitByClaimant":
      return "Quit by Claimant";
    case "quitByRespondent":
      return "Quit by Respondent";
    case "resolutionFailed":
      return "Resolution Failed";
    case "closedByCaseManager":
      return "Closed By CaseManager";
    case "settledAndWithdrawn":
      return "Settled & Withdrawn";
    case "updatedAndWithdrawn":
      return "Updated & Withdrawn";
    case "thirdHearing":
      return "3rd Hearing";
    case "firstMom":
      return "1st MOM";
    case "commencementLetter":
      return "Commencement Letter";
    case "referenceLetter":
      return "Reference Letter";
    case "secondHearing":
      return "2nd Hearing";
    case "firstHearing":
      return "1st Hearing";
    default:
      return _.startCase(detailedStage);
  }
}

export const polarToCartesian = (percent) => {
  const angle = percent * 2 * Math.PI - Math.PI / 2;
  return [16 + Math.cos(angle) * 16, 16 + Math.sin(angle) * 16];
};

export const getColorForKey = (key) => {
  const colorMap = {
    Initiated: "#98DDCA",
    InProgress: "#D5ECC2",
    Completed: "#FFD3B4",
    "Awaiting Case Fee": "#8DECB4",
    "Resolution Closed": "#FFAAA7",
    "Awaiting Payment": "#B5CFB7",
    "Waiting For Case Approval": "#9CA986",
    "Waiting For Respondent": "#DCA47C",
    "Notice to Arbitrate": "#EF5A6F",
    "Arbitration Referenced": "#667BC6",
    "Arbitrator Nominated": "#3AA6B9",
    "Arbitrator Confirmed": "#A1DD70",
    "Waiting for Arbitrator Confirmation": "#B1B2FF",
    "First Notice Sent": "#5A639C",
    "Statement of Claim Filed": "#FFDE95",
    "Awaiting for Sec 17 details": "#D2649A",
    "Sec 17 Petition Filed": "#7BD3EA",
    "Sec 17 Ordered": "#FBA834",
    "Awaiting Sec 17 Execution": "#C3FF93",
    "Sec 17 Extension Memo Filed": "#15F5BA",
    "Sec 17 Extension Memo Ordered": "#40128B",
    "FS Ordered": "#FFFFD0",
    "Attachment Ordered": "#B3FFAE",
    "Garnishee Prohibitary Order": "#49FF00",
    "Garnishee Final Order": "#FF00E4",
    "Sec 17 Closed": "#D7E9B9",
    "Statement of Defence Filed": "#D4E7C5",
    "Rejoinder from Claimant": "#C3ACD0",
    "Surrejoinder From Respondent": "#BEADFA",
    "2nd Notice MOM": "#CDF0EA",
    "Evidence Filed by Claimant": "#8C3061",
    "Evidence Filed by Respondent": "#677D6A",
    "Cross Examination": "#A2678A",
    "Arguments by Claimant": "#DEF5E5",
    "Arguments by Respondent": "#B6EADA",
    "Under Resolution": "#CDE8E5",
    "Negotiation Reached": "#8B9A46",
    "Claimant paid Success Fee": "#FF7D29",
    "Respondent paid Success Fee": "#B784B7",
    "Both paid NegotiationFee": "#DC84F3",
    "Reserved for Award": "#F4DFC8",
    "Award Passed": "#E6FF94",
    "Quit by Claimant": "#DCF2F1",
    "Quit by Respondent": "#005B41",
    "Resolution Failed": "#9B4444",
    "Closed By CaseManager": "#E8A0BF",
    "Settled & Withdrawn": "#95D2B3",
    "Updated & Withdrawn": "#AEE1FC",
    "Awaiting Agreement": "#93B5C6",
    "2nd Hearing": "#FF76CE",
    "1st Hearing": "#A1EEBD",
    "3rd Hearing": "#FF4B91",
    "1st MOM": "#A6D0DD",
    "Commencement Letter": "#FFE5F1",
    "Reference Letter": "#AE445A",
  };
  return colorMap[key] || getRandomColor();
};

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const daysCount = (rowData) => {
  const timeFormat = Object.entries(rowData?.caseStatusUpdatedTime)
    .map(([detailedStage, time]) => ({
      detailedStage,
      time,
    }))
    .sort((a, b) => {
      let dateA = a.time.date.split(/[/ :]/);
      let dateB = b.time.date.split(/[/ :]/);

      // Convert to a format recognized by the Date constructor (MM/DD/YYYY HH:mm:ss)
      dateA = new Date(
        `${dateA[1]}/${dateA[0]}/${dateA[2]} ${dateA[3]}:${dateA[4]}:${dateA[5]}`,
      );
      dateB = new Date(
        `${dateB[1]}/${dateB[0]}/${dateB[2]} ${dateB[3]}:${dateB[4]}:${dateB[5]}`,
      );

      // For ascending order
      return dateA - dateB;
    })
    .map((data, idx) => data);

  const parseDate = (dateString) => {
    // Convert the string to a Date object using the format dd/mm/yyyy hh:mm:ss
    const [datePart, timePart] = dateString?.split(" ");
    const [day, month, year] = datePart?.split("/").map(Number);
    const [hours, minutes, seconds] = timePart?.split(":").map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  let date1 = "";
  let date2 = "";

  if (timeFormat?.length > 0) {
    date1 = parseDate(timeFormat[0].time.date);
    date2 = parseDate(timeFormat[timeFormat.length - 1].time.date);
  }

  const differenceInTime = date2 - date1; // Difference in milliseconds
  const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days

  return Math.round(differenceInDays);
};

export const stagesTimeLine = (rowData) => {
  return Object.entries(rowData?.caseStatusUpdatedTime)
    .map(([detailedStage, time]) => ({
      detailedStage,
      time,
    }))
    .sort((a, b) => {
      let dateA = a.time.date.split(/[/ :]/);
      let dateB = b.time.date.split(/[/ :]/);

      // Convert to a format recognized by the Date constructor (MM/DD/YYYY HH:mm:ss)
      dateA = new Date(
        `${dateA[1]}/${dateA[0]}/${dateA[2]} ${dateA[3]}:${dateA[4]}:${dateA[5]}`,
      );
      dateB = new Date(
        `${dateB[1]}/${dateB[0]}/${dateB[2]} ${dateB[3]}:${dateB[4]}:${dateB[5]}`,
      );

      // For ascending order
      return dateA - dateB;
    })
    .map((arr, idx) => arr);
};

const parseDate = (dateString) => {
  const [datePart, timePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

// Calculate days difference between consecutive dates
export const calculateDaysBetween = (data) => {
  const daysBetween = [];
  for (let i = 0; i < data.length - 1; i++) {
    const currentDate = parseDate(data[i].time.date);
    const nextDate = parseDate(data[i + 1].time.date);

    // Calculate difference in milliseconds
    const differenceInTime = nextDate - currentDate;

    // Convert milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    daysBetween.push({
      from: _.startCase(data[i].detailedStage),
      to: _.startCase(data[i + 1].detailedStage),
      days: Math.round(differenceInDays),
    });
  }
  return daysBetween;
};

export function daysFromNow(dateString) {
  let caseCreateDate = dateString.split(" ")[0];
  const [day, month, year] = caseCreateDate.split("/").map(Number);

  // Create a Date object using the parsed values
  // Note: month - 1 because months in JavaScript Date objects are 0-indexed (0 = January, 11 = December)
  const inputDate = new Date(year, month - 1, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in time in milliseconds
  const timeDifference = currentDate - inputDate;

  // Convert the time difference from milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference + 1;
}

export function calculateDaysBetweenDates(datesArray) {
  for (let i = 0; i < datesArray.length - 1; i++) {
    const date1 = parseDate(datesArray[i].time.date);
    const date2 = parseDate(datesArray[i + 1].time.date);

    // Calculate the difference in time in milliseconds
    const timeDifference = date2 - date1;

    // Calculate the days difference correctly by truncating towards zero if days difference is partial
    let daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));

    // Set daysDifference to 0 if dates are on the same day with any time
    if (timeDifference === 0) {
      daysDifference = 0;
    }

    // Set the daysDifference in the current object
    datesArray[i].daysDifference = daysDifference;
  }

  // Remove the daysDifference key from the last object
  // delete datesArray[datesArray.length - 1].daysDifference;

  return datesArray;
}
