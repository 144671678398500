import React, { useState } from "react";
import { default as ReactSelect, components } from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    width: "100%", // This ensures the select box takes the full width of the container
    "&:hover": { borderColor: "#e1e3ee" }, // border style on hover
    border: "1px solid #e1e3ee", // default border color
    boxShadow: "none", // no box-shadow
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    fontSize: "13px",
    cursor: "pointer",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#293461", // Custom text color
    fontWeight: "bold", // Bold text
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#293461", // Remove icon color
    borderRadius: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px", // Change this to the desired font size
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#293461" : "#293461",
    backgroundColor: state.isSelected ? "lightblue" : provided.backgroundColor,
  }),
};

const allOption = {
  label: "Select all",
  value: "*",
};

const MySelect = (props) => {
  const [inputText, setInputText] = useState("");

  /**
   * @description Multivalue select tags
   */

  const MultiValue = React.memo((props) => {
    const { index, selectProps } = props;
    const maxToShow = selectProps.maxDisplayedOptions || 1; // Set default max to show

    if (index >= maxToShow) {
      if (index === maxToShow) {
        return (
          <components.MultiValue {...props}>
            <span>+{props.selectProps.value.length - maxToShow} more</span>
          </components.MultiValue>
        );
      }
      return null; // Do not render additional chips
    }

    return <components.MultiValue {...props} />;
  });

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([allOption, ...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter(
                  (option) => option.value !== allOption.value,
                );
              } else if (event.action === "select-option") {
                result = [allOption, ...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
        noOptionsMessage={() => "No Party found"}
        placeholder="Select party"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          MultiValue,
          // Option: CheckboxOption,
        }}
        styles={customStyles}
        inputValue={inputText}
        onInputChange={(newValue, actionMeta) => {
          if (actionMeta.action === "input-change") {
            setInputText(newValue);
          }
        }}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      noOptionsMessage={() => "No Party found"}
      placeholder="Select party"
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{
        MultiValue,
        // Option: CheckboxOption,
      }}
      styles={customStyles}
      inputValue={inputText}
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === "input-change") {
          setInputText(newValue);
        }
      }}
    />
  );
};

export default MySelect;
