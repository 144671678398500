import React, { useState, useRef, Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { navigate } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useEffect } from "react";
import {
  numberFormat,
  getCaseLabelByStatus,
  parseTimeStamp,
  getErrorMessage,
} from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import useCartCount from "../../../hooks/useCartCount";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import usePrevious from "../../../hooks/usePrevious";

const initFilters = [
  { label: "All", key: "allCount", value: 0 },
  {
    label: "Waiting For Respondent",
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  { label: "Under Resolution", key: "underResolution", value: 0 },
  { label: "Settled", key: "settled", value: 0 },
  // { label: "Drafts", key: "draftCount", value: 0 },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Drafts":
      value = "draft";
      break;
    case "Under Resolution":
      value = "underResolution";
      break;
    case "Waiting For Respondent":
      value = "awaitingRespondent";
      break;
    case "Settled":
      value = "settled";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCases(query = "", caseType) {
  try {
    const param = `?resolutionKind=${caseType}`;
    const response = await CaseService.getCases(query);
    const stats = await CaseService.caseStats(param);
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

let previousCaseType;

const Index = ({ id, caseType = null, currentCaseType }) => {
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const placeholderText = "Search Cases";
  const [selectedFilter, setSelectedFilter] = useState();
  const MTRef = useRef();
  const { setStats } = useCartCount();
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const filterPreviousValue = usePrevious(filters);

  function setCaseStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (caseType && filterPreviousValue) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseType]);

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  const columns = [
    {
      field: "id",
      title: "Case ID",
      sorting: true,
      tooltip: "Unique Identifier for the Case across JustAct platform",
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData.id}
        </HyperLink>
      ),
      headerStyle: {
        maxWidth: 100,
        width: 100,
        paddingLeft: "26px",
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`,
      },
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData.title}
        </div>
      ),
      sorting: false,
    },
    {
      field: "party",
      title: "Party",
      tooltip: "Parties representing to file the case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.claimantParty?.name}
        </div>
      ),
      //render: (rowData) => <>{rowData?.claimantParty?.name}</>,
      sorting: false,
    },
    {
      field: "respondentName",
      title: "Counterparty",
      tooltip: "Party countering your case",
      sorting: false,
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </div>
      ),
    },
    {
      field: "totalClaimValue",
      title: "Claim Value",
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const divStyle = {
          cursor: "pointer",
        };
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return (
            <div
              style={divStyle}
              onClick={() =>
                navigate(
                  `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
                )
              }
            >
              {numberFormat(
                parseFloat(caseItems).toFixed(2),
                rowData?.currencyUnit,
              )}
            </div>
          );
        else
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(
                  `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
                )
              }
            >
              {"Non Monetary"}
            </div>
          );
      },
      sorting: true,
    },
    {
      field: "nextHearingDate",
      title: labels["table.nextHearingDate"],
      render: (rowData) => (
        <div
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() => getMeetingjoin(rowData.id)}
        >
          {rowData?.nextHearingDate?.date
            ? rowData?.nextHearingDate?.date
            : "-"}
        </div>
      ),
      sorting: true,
      headerStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
    },
    {
      field: "status",
      title: "Status",
      tooltip: "Status of the Case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {getCaseLabelByStatus(rowData)}
        </div>
      ),
    },
    {
      field: "loanAccountNo",
      title: "Contract Number",
      hidden: caseType === "arbitration" ? false : true,
      render: (rowData) =>
        rowData.loanAccountNo ? (
          <div
            onClick={() =>
              navigate(
                `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
              )
            }
          >
            {rowData.loanAccountNo}
          </div>
        ) : (
          <div style={{ marginLeft: "25px", cursor: "pointer" }}>{"-"}</div>
        ),
      sorting: false,
      headerStyle: {
        padding: `12px 25px`,
      },
    },
    {
      field: "submittedOn",
      title: "Created",
      tooltip: "Case Created Date",
      headerStyle: {
        width: 120,
      },
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/view/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {moment(
            rowData.submittedOn
              ? rowData.submittedOn
              : parseTimeStamp(rowData.created_at),
          ).format("DD/MM/YYYY")}
        </div>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (previousCaseType !== currentCaseType) {
        params.page = 1;
        setPage(params.page);
      }
      previousCaseType = currentCaseType;
      if (query.search) {
        params.search = query.search;
      }
      if (caseType) {
        params.resolutionKind = caseType;
      }
      if (id) {
        params.caseManagerId = id;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams, caseType)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
            setCaseStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <Fragment>
      <CustomTable
        hidePagination={state.lastPage === 1}
        pageSize={state?.data?.length ? state?.data?.length : 10}
        pluralTitle="Cases"
        singularTitle=""
        {...{
          columns,
          data,
          filters,
          selectedFilter,
          setSelectedFilter,
          placeholderText,
          MTRef,
          page,
          setPage,
        }}
        state={state}
      />
    </Fragment>
  );
};

export default Index;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
