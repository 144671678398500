import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { shortTimecode } from "../TimeCodeConverter";
import { isArray } from "lodash";
import { Tooltip } from "@material-ui/core";

const Index = ({
  data,
  timecodeOffset,
  showTimecodes,
  currentTime,
  showSpeakers,
  handleWordClick,
  render,
  setRender,
}) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);

  useEffect(() => {
    const response = splitContent(data, currentTime);
    setValue(response);
    setRender(false);
    if (value) {
      editor.children = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  function generatePreviousTimingsUpToCurrent(start, end) {
    const startTimeInt = parseInt(start);
    const endTimeInt = parseInt(end);
    return `${startTimeInt} ${endTimeInt}`;
  }

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    let startTimecode = props.element?.start;
    if (timecodeOffset) {
      startTimecode += timecodeOffset;
    }

    return (
      <React.Fragment>
        <DrawerContainer style={{ display: "flex" }} contentEditable={false}>
          {showSpeakers ? (
            <Container>
              {/* <Person style={{ fill: COLORS.BTN_GREEN, cursor: "pointer" }} /> */}
              <ProfileImage
                src={require("../../../assets/images/userPlaceholder.svg")}
              />
              <ValueSpeaker style={{ marginLeft: "5px" }}>
                {props.element.speaker}
              </ValueSpeaker>
            </Container>
          ) : null}
          {showTimecodes ? (
            <ValueTimesCode
              data-start={props.element.start}
              onClick={() => handleWordClick(startTimecode)}
            >
              {shortTimecode(startTimecode)}
            </ValueTimesCode>
          ) : null}{" "}
          {props.element?.confidence ? (
            <ValueConfidence>{`${props.element?.confidence} %`}</ValueConfidence>
          ) : null}
          <ValueIds>{props.element?.id}</ValueIds>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
          onDoubleClick={() => handleWordClick(startTimecode)}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const generateText = (words) => {
    return words.text;
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      start: paragraph.start,
      confidence: paragraph.confidence,
      id: paragraph.id,
      end: paragraph.end,
      previousTimings: generatePreviousTimingsUpToCurrent(
        paragraph.start,
        paragraph.end,
      ),
      mediaId: paragraph?.mediaId,
      // pre-computing the display of the formatting here so that it doesn't need to convert it in leaf render
      startTimecode: paragraph.start,
      type: "timedText",
      children: isArray(paragraph.text)
        ? paragraph.text
        : [
            {
              text: generateText(paragraph),
            },
          ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    if (leaf["suggestionText"] || leaf["name"]) {
      return (
        <Tooltip
          title={
            leaf["name"]
              ? `${
                  leaf["suggestionText"] ? leaf["suggestionText"] : leaf["role"]
                }  - ${leaf["name"]}`
              : leaf["suggestionText"]
          }
          placement="top"
        >
          <span
            className={"timecode text"}
            style={{
              borderRadius: "5px",
              backgroundColor: leaf["color"],
            }}
            data-start={children.props.parent.start}
            data-previous-timings={children.props.parent.previousTimings}
            {...attributes}
          >
            {children}
          </span>
        </Tooltip>
      );
    }
    return (
      <span
        className={"timecode text"}
        style={{
          borderRadius: "5px",
          backgroundColor: leaf["color"],
        }}
        data-start={children.props.parent.start}
        data-previous-timings={children.props.parent.previousTimings}
        {...attributes}
      >
        {children}
      </span>
    );
  }, []);

  const handleTimeClick = () => {
    editor.selection = null;
  };

  const highlightText = (currentTime) => {
    let boolean;
    if (currentTime) {
      for (let i = 0; i < value.length; i++) {
        let startTime = parseInt(value[i].start);
        let endTime = parseInt(value[i].end);
        if (startTime <= currentTime && endTime >= currentTime) {
          boolean = `${startTime} ${endTime}`;
          break;
        }
      }
      return boolean;
    }
  };

  return (
    <>
      <React.Fragment>
        <style scoped>
          {`
             .timecode[data-previous-timings*="${highlightText(currentTime)}"]{
                  color:  ${COLORS.BTN_GREEN};
              }
          `}
        </style>
        {value.length !== 0 ? (
          <Slate editor={editor} value={value}>
            <Editable
              readOnly={true}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onMouseDown={handleTimeClick}
            />
          </Slate>
        ) : null}
      </React.Fragment>
    </>
  );
};

export default Index;

const Container = styled.div`
  display: flex;
`;
const DrawerContainer = styled.div`
  width: 100%;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueTimesCode = styled.span`
  width: 80px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  &:hover {
    text-decoration: underline;
  }
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 68px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueIds = styled.span`
  width: 50px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 5px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const CustomTextAreaOtter = styled.div`
  width: 85%;
  font-size: 16px;
  margin-left: 125px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

export const QuitContainer = styled.div`
  padding: 18px;
  margin-left: 117px;
  margin-right: 25px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const ProfileImage = styled.img`
  width: 20px;
  height: 25px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 4px;
  margin-left: 5px;
`;
