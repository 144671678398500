export const pie_chart_group = {
  "Case Initiated": [
    "draft",
    "pendingRegistrationFee",
    "waitingForCaseApproval",
    "awaitingRespondentAcceptance",
    "noticeToArbitrate",
    "arbitrationReferenced",
    "arbitrationNominated",
    "arbitrationConfirmed",
    "waitingForArbitratorConfirmation",
  ],
  "Case In progress": [
    "firstNoticeSent",
    "statementOfClaimFiled",
    "awaitingForSec17Details",
    "sec17PetitionFiled",
    "sec17Ordered",
    "awaitingSec17Execution",
    "sec17ExtensionMemoFiled",
    "sec17ExtensionMemoOrdered",
    "fsOrdered",
    "attachmentOrdered",
    "garnisheeProhibitaryOrder",
    "garnisheeFinalOrder",
    "sec17Closed",
    "statementOfDefenceFiled",
    "rejoinderFromClaimant",
    "surrejoinderFromRespondent",
    "2ndNoticeMOM",
    "evidenceFiledByClaimant",
    "evidenceFiledByRespondent",
    "crossExamination",
    "argumentsByClaimant",
    "argumentsByRespondent",
    "negotiationOngoing",
  ],
  "Case Completed": [
    "negotiationReached",
    "claimantPaidNegotiationSuccessFee",
    "respondentPaidNegotiationSuccessFee",
    "bothPaidNegotiationSuccessFee",
    "reservedForAward",
    "awardPassed",
  ],
  "Resolution Closed": [
    "quitByClaimant",
    "quitByRespondent",
    "resolutionFailed",
    "closedByCaseManager",
    "settledAndWithdrawn",
    "updatedAndWithdrawn",
  ],
};

export const colWidths = [
  100, 250, 100, 150, 150, 150, 210, 150, 150, 150, 150, 150, 150, 150, 150,
  150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150,
];
